/* InstallPromptComponent.css */
.installPrompt {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -2px 4px rgba(0,0,0,.2);
    z-index: 1000;
}

.installPrompt p {
    margin-right: 12px;
}

.installPrompt button {
    padding: 10px 20px;
    background-color: #da1266;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.promptLogo {
    width: 48px;
    height: 30px;
    margin-right: 5px;
}
.closeButton {
    margin-left: 10px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 20px; /* Kapat butonunun boyutunu ayarlar */
}
.gallery {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
}

.gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery > div {
    position: relative;
    grid-row-end: span 2;
}

@media (max-width: 600px) {
    .gallery {
        grid-template-columns: 1fr;
    }
}

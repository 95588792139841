/* CSS */
.video-container {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow items to wrap to multiple lines */
    justify-content: space-between; /* Space out the items evenly if they don't fill the last row entirely */
    padding: 10px; /* Optional: adds padding inside the container for better spacing from the container edges */
}

.video-wrap {
    flex: 1 0 calc(33.33% - 20px); /* Adjust the basis to 1/3rd of the container width minus the total margin */
    margin: 10px; /* Provides spacing between videos */
}

iframe {
    width: 100%; /* Make the iframe responsive to the container's width */
    height: auto; /* Adjust height automatically */
    aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .video-container {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }

    .video-wrap {
        flex: 1 0 calc(100% - 20px); /* Make videos take full width of the container minus margins */
    }
}
